import Swal from 'sweetalert2';

export default {
    // Careful | in cases where the mixin and the component define 
    // the same property, the component's property will take precedence.
    watch: {

    },
    data() {
        return {
            renewalOptions: null,
            renewalOptionSelect: null,
            stepHeaderConfig: {
                stepHeading: "",
                stepSubHeading: "",
                currentStep: 0,
                totalSteps: 0
            },
            token: null,
            cardNumber: null,
            cardExpiry: null,
            isAbsolute: false,
            renewalContactPhone: "866-937-7387",
            editPayment: false,
        }
    },
    computed: {
        leadId() {
            return this.$store.getters.leadId;
        },
    },
    async created() {
        if (this.$route.params.id && this.$route.query.token) {
            let parameters = {
                policyId: parseInt(this.$route.params.id),
                token: this.$route.query.token,
                optionId: null
            }

            this.renewalOptions = await this.$store.dispatch("getRenewalOptions", parameters);
            this.addStepHeader(this.renewalOptions);

            if(this.renewalOptions && this.renewalOptions.tokenExpired === true) {
                var ldo = {
                    eventName: 'quoteError',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Renewal Quote',
                        errorMessage: 'Link Expired',
                        leadId: this.leadId,
                        petType: this.renewalOptions.petDetails[0].species
                    }
                }
                digitalData.eventTrack( 'quoteError',ldo);
            }

            if(this.renewalOptions && this.renewalOptions.tokenExpired === false) {
                var ldo = {
                    eventName: 'quoteStart',
                    attributes: { 
                        application: 'MLPI',
                        form:'Pet Insurance Renewal Quote'
                    }
                }
                digitalData.eventTrack('quoteStart', ldo);
            }

            if (this.renewalOptions == null || this.renewalOptions.policyId == 0) {
                let ldo = {
                    eventName: 'quoteError',
                    attributes: {
                        application: 'MLPI',
                        form: 'Pet Insurance Renewal Quote',
                        errorMessage: 'Quote Error'
                    }
                }
                digitalData.eventTrack('quoteError', ldo);
            }

            if (this.$route.query.optionId) {
                let intOptionId = parseInt(this.$route.query.optionId);
                const exist = this.renewalOptions.plans.some(plan => plan.optionId === intOptionId);
                if (exist) {
                    this.renewalOptionSelect = intOptionId;
                }
            }
        } else {
            this.renewalOptions = undefined;
        }
    },
    mounted() {
        // Set Zenapse Page Change
        let checkExist = setInterval(() => {
            if (typeof Zenapse !== 'undefined') {
                clearInterval(checkExist);
                Zenapse.methods.loadPageEvents();
            }
        }, 100);

        setTimeout(() => clearInterval(checkExist), 30000);

        this.delay(1000).then(() => this.runInvoca());
    },
    methods: {
        checkIsDefaultPlan() {
            const defaultPlan = this.renewalOptions.plans.find((plan) => plan.isDefault);

            let planIndex = null;
            if (defaultPlan) {
                planIndex = defaultPlan.optionId;
                renewalOptionSelect = planIndex;
            }
            return planIndex;
        },
        useCardOnFile() {
            this.token = null;
            this.editPaymentCard.last4 = '';
            this.editPaymentCard.exp_month = '';
            this.editPaymentCard.exp_year = '';
            this.editPaymentCard.brand = '';

            this.editPayment = !this.editPayment;
        },
        async submitRenewal() {
            const selectedPlan = this.renewalOptions.plans.find(plan => plan.optionId === this.renewalOptionSelect);

            return await this.$store.dispatch("saveRenewalOption", {
                policyId: this.renewalOptions.policyId,
                optionId: selectedPlan.optionId,
                payload: {
                    "OptionId": selectedPlan.optionId,
                    "PlanDetails": {
                        "PlanLimit": selectedPlan.planLimit,
                        "Deductible": selectedPlan.deductible,
                        "Reimbursement": selectedPlan.reimbursement,
                        "Premium": selectedPlan.premium
                    },
                    "StripeToken": this.token ? this.token.id : "",
                }
            });
        },
        showFooter() {
            if (!this.renewalOptions) return false;

            // Token exired
            if (this.renewalOptions.policyId !== 0 && this.renewalOptions.tokenExpired === true) return false;

            // Invalid Policy or Token
            if (this.renewalOptions.policyId === 0) return false;

            return true;
        },
        addStepHeader(renewalInfo) {
            // Token exired
            if (renewalInfo.policyId !== 0 && renewalInfo.tokenExpired === true) {
                this.stepHeaderConfig = {
                    stepHeading: "Peace of mind sounds pretty good, doesn't it?",
                    stepSubHeading: "Renew your pet's insurance today.",
                    currentStep: null,
                    totalSteps: null
                };

                return;
            }

            // Normal flow
            if (renewalInfo.policyId !== 0 && renewalInfo.tokenExpired === false) {
                this.stepHeaderConfig = {
                    stepHeading: "Finding the right plan is like finding the right pet.",
                    stepSubHeading: "We want you to have your perfect match.",
                    currentStep: 1,
                    totalSteps: 3
                };

                return
            }

            // Invalid Policy or Token
            if (this.renewalOptions.policyId === 0) {
                this.stepHeaderConfig = {
                    stepHeading: "Peace of mind sounds pretty good, doesn't it?",
                    stepSubHeading: "Renew your pet's insurance today.",
                    currentStep: null,
                    totalSteps: null
                };

                return;
            }
        },
        toggleEditPayment() {
            this.editPayment = !this.editPayment;

            var ldo = {
                eventName: 'linkClick',
                attributes: {
                    application: 'MLPI',
                    form: 'Pet Insurance Renewal Quote',
                    linkName: 'Edit Payment Info'
                }
            }
            digitalData.eventTrack('linkClick', ldo);
        }
    }
}