<template>
    <div>
        <v-form>
            <v-row>
                <v-col>
                    <p class="body-1" id="paymentFormTitle" tabindex="0">Credit Card Information</p>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="12">
                    <div class="stripe-field" id="card-number"></div>
                </v-col>

                <v-col cols="6">
                    <div class="stripe-field" id="card-expiry"></div>
                </v-col>

                <v-col cols="6">
                    <div class="stripe-field" id="card-cvc"></div>
                </v-col>

                <v-col cols="12">
                    <div id="card-error"></div>
                </v-col>
            </v-row>
        </v-form>
    </div>
</template>

<script>
    export default {
        name: "QFPaymentInfo",
        data() {
            return {
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
            };
        },
        computed: {
            stripeElements() {
                return this.$stripe.elements();
            },
        },
        mounted() {
            this.initDOMStripeElements();
        },
        methods: {
            async submit() {
                const { token, error } = await this.$stripe.createToken(this.cardNumber);

                if (error) {
                    document.getElementById('card-error').innerHTML = error.message;
                    this.stripeErrorsFocus();
                    return null;
                }

                if(document.getElementById('card-error')) document.getElementById('card-error').innerHTML = "";

                return token;
            },
            initDOMStripeElements: function () {
                this.cardNumber = this.stripeElements.create('cardNumber');
                this.cardNumber.mount('#card-number');

                this.cardExpiry = this.stripeElements.create('cardExpiry');
                this.cardExpiry.mount('#card-expiry');

                this.cardCvc = this.stripeElements.create('cardCvc');
                this.cardCvc.mount('#card-cvc');
            },
            stripeErrorsFocus() {
                this.cardNumber.update();
                if (this.cardNumber._invalid || this.cardNumber._empty) {
                    this.cardNumber.focus();
                } else if (this.cardExpiry._invalid || this.cardExpiry._empty) {
                    this.cardExpiry.focus();
                } else if (this.cardCvc._invalid || this.cardCvc._empty) {
                    this.cardCvc.focus();
                }
            }
        }
    }
</script>